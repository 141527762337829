var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c("v-app", [
        _c(
          "div",
          { staticClass: "inner-container" },
          [
            _c(
              "div",
              {
                staticClass:
                  "top-page-header setting-top page-header-with-banner plan-top-header ",
                attrs: { lang: _vm.locale },
              },
              [
                _c(
                  "v-tabs",
                  {
                    staticClass: "custom-tab ",
                    attrs: { "background-color": "transparent" },
                    model: {
                      value: _vm.tabs,
                      callback: function ($$v) {
                        _vm.tabs = $$v
                      },
                      expression: "tabs",
                    },
                  },
                  _vm._l(_vm.items, function (item) {
                    return _c("v-tab", { key: item }, [
                      _vm._v(" " + _vm._s(item) + " "),
                    ])
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "v-container",
              {
                staticClass: "v-main-content automation-top stepper-template",
                attrs: { fluid: "" },
              },
              [
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "pa-4 pt-0 rounded-lg extra-padding",
                      attrs: { elevation: "3" },
                    },
                    [
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.tabs,
                            callback: function ($$v) {
                              _vm.tabs = $$v
                            },
                            expression: "tabs",
                          },
                        },
                        [
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pt-0" },
                                    [
                                      _c(
                                        "v-form",
                                        {
                                          ref: "userDetailSettingForm",
                                          model: {
                                            value: _vm.valid,
                                            callback: function ($$v) {
                                              _vm.valid = $$v
                                            },
                                            expression: "valid",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "profile-col mt-0" },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "mt-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "6",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "h3",
                                                        {
                                                          staticClass:
                                                            "heading--text mb-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "settings.profileInfo"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                { staticClass: "mt-2" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "5",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "teamMgmt.first"
                                                          ),
                                                          placeholder:
                                                            "First Name",
                                                          required: "",
                                                          outlined: "",
                                                          dense: "",
                                                          rules: _vm.nameRules,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.updateDetails
                                                              .FirstName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.updateDetails,
                                                              "FirstName",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "updateDetails.FirstName",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "5",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "teamMgmt.last"
                                                          ),
                                                          placeholder:
                                                            "Last Name",
                                                          required: "",
                                                          outlined: "",
                                                          dense: "",
                                                          rules: _vm.nameRules,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.updateDetails
                                                              .LastName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.updateDetails,
                                                              "LastName",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "updateDetails.LastName",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                { staticClass: "mt-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "5",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "teamMgmt.email"
                                                          ),
                                                          placeholder: "Email",
                                                          required: "",
                                                          outlined: "",
                                                          dense: "",
                                                          disabled: "",
                                                        },
                                                        model: {
                                                          value: _vm.email,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.email = $$v
                                                          },
                                                          expression: "email",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "5",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "teamMgmt.job"
                                                          ),
                                                          placeholder:
                                                            "Job Title",
                                                          required: "",
                                                          outlined: "",
                                                          dense: "",
                                                          rules: _vm.jobRules,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.updateDetails
                                                              .JobTitle,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.updateDetails,
                                                              "JobTitle",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "updateDetails.JobTitle",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                { staticClass: "mt-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "mr-3 w-xsm-100 mb-2",
                                                          attrs: {
                                                            color: "primary",
                                                            depressed: "",
                                                            disabled: !_vm.valid,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.updateProfile()
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "settings.updateProf"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "w-xsm-100 mb-2",
                                                          attrs: {
                                                            color: "primary",
                                                            depressed: "",
                                                          },
                                                          on: {
                                                            click: _vm.goToCP,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "changePass.chngPass"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pt-0" },
                                    [
                                      _c(
                                        "v-form",
                                        {
                                          ref: "userDetailSettingForm",
                                          model: {
                                            value: _vm.valid1,
                                            callback: function ($$v) {
                                              _vm.valid1 = $$v
                                            },
                                            expression: "valid1",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "profile-col mt-0" },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "mt-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "6",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "h3",
                                                        {
                                                          staticClass:
                                                            "heading--text mb-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                this.$t(
                                                                  "billingDetails.billInfo"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                { staticClass: "mt-2" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "5",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "billingDetails.compName"
                                                          ),
                                                          placeholder: _vm.$t(
                                                            "billingDetails.compName"
                                                          ),
                                                          outlined: "",
                                                          dense: "",
                                                          rules: _vm.nameRules,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.billingAddress
                                                              .company,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.billingAddress,
                                                              "company",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "billingAddress.company",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "5",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label:
                                                            _vm.$t(
                                                              "billingDetails.addLine"
                                                            ) + "1",
                                                          placeholder:
                                                            _vm.$t(
                                                              "billingDetails.addLine"
                                                            ) + "1",
                                                          outlined: "",
                                                          dense: "",
                                                          rules: _vm.generalVal,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.billingAddress
                                                              .line1,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.billingAddress,
                                                              "line1",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "billingAddress.line1",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                { staticClass: "mt-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "5",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label:
                                                            _vm.$t(
                                                              "billingDetails.addLine"
                                                            ) + "2",
                                                          placeholder:
                                                            _vm.$t(
                                                              "billingDetails.addLine"
                                                            ) + "2",
                                                          outlined: "",
                                                          dense: "",
                                                          rules: _vm.generalVal,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.billingAddress
                                                              .line2,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.billingAddress,
                                                              "line2",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "billingAddress.line2",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "5",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label:
                                                            _vm.$t(
                                                              "billingDetails.addLine"
                                                            ) + "3",
                                                          placeholder:
                                                            _vm.$t(
                                                              "billingDetails.addLine"
                                                            ) + "3",
                                                          outlined: "",
                                                          dense: "",
                                                          rules: _vm.generalVal,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.billingAddress
                                                              .line3,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.billingAddress,
                                                              "line3",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "billingAddress.line3",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                { staticClass: "mt-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "5",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "billingDetails.city"
                                                          ),
                                                          placeholder: _vm.$t(
                                                            "billingDetails.city"
                                                          ),
                                                          outlined: "",
                                                          dense: "",
                                                          rules: _vm.generalVal,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.billingAddress
                                                              .city,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.billingAddress,
                                                              "city",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "billingAddress.city",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "5",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "billingDetails.zip"
                                                          ),
                                                          placeholder: _vm.$t(
                                                            "billingDetails.zip"
                                                          ),
                                                          outlined: "",
                                                          dense: "",
                                                          rules: _vm.generalVal,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.billingAddress
                                                              .zip,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.billingAddress,
                                                              "zip",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "billingAddress.zip",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                { staticClass: "mt-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "5",
                                                      },
                                                    },
                                                    [
                                                      _c("v-autocomplete", {
                                                        attrs: {
                                                          clearable: "",
                                                          outlined: "",
                                                          placeholder: _vm.$t(
                                                            "createCampaign.countries"
                                                          ),
                                                          dense: "",
                                                          chips: "",
                                                          "small-chips": "",
                                                          "deletable-chips": "",
                                                          label: _vm.$t(
                                                            "createCampaign.countries"
                                                          ),
                                                          items: _vm.countries,
                                                          "item-text": "Name",
                                                          "item-value": "Code",
                                                        },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "item",
                                                            fn: function (
                                                              data
                                                            ) {
                                                              return [
                                                                [
                                                                  _c(
                                                                    "country-flag",
                                                                    {
                                                                      attrs: {
                                                                        country: data.item.Code.toLowerCase(),
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    {
                                                                      staticClass:
                                                                        "mt-2 mx-2",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              data
                                                                                .item
                                                                                .Name
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                              ]
                                                            },
                                                          },
                                                        ]),
                                                        model: {
                                                          value:
                                                            _vm.billingAddress
                                                              .country,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.billingAddress,
                                                              "country",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "billingAddress.country",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "5",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "billingDetails.vat"
                                                          ),
                                                          placeholder: _vm.$t(
                                                            "billingDetails.vat"
                                                          ),
                                                          outlined: "",
                                                          dense: "",
                                                          rules: _vm.generalVal,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.billingAddress
                                                              .vat_number,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.billingAddress,
                                                              "vat_number",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "billingAddress.vat_number",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                { staticClass: "mt-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "5",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "mr-3",
                                                          attrs: {
                                                            color: "primary",
                                                            depressed: "",
                                                            disabled: !_vm.valid1,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.updateBillingAddr()
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              this.$t(
                                                                "billingDetails.updateBillAdd"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("LoadingAlert", {
                    attrs: {
                      loading: _vm.loadingAlertFlag,
                      loadingText: _vm.loadingAlertText,
                    },
                  }),
                  _c("InfoAlert", {
                    attrs: {
                      InfoAlert: _vm.infoAlertFlag,
                      InfoAlertTitle: _vm.infoAlertTitle,
                      InfoAlertMessage: _vm.infoAlertMessage,
                    },
                    on: { emitInfoAlertOkClicked: _vm.clearInfoAlert },
                  }),
                  _c("DecisionAlert", {
                    attrs: {
                      DecisionAlert: _vm.decisionAlertFlag,
                      DecisionAlertTitle: _vm.decisionAlertTitle,
                      DecisionAlertMessage: _vm.decisionAlertMessage,
                      DecisionAlertData: _vm.decisionAlertData,
                    },
                    on: {
                      emitDecisionAlertCancelClicked: _vm.clearDecisionModel,
                      emitDecisionAlertOkClicked: function ($event) {
                        return _vm.decisionOkAction($event)
                      },
                    },
                  }),
                ],
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }