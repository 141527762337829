<template flex>
  <div class="dashboard">
    <v-app>
      <!-- Sizes your content based upon application components -->
      <div class="inner-container">
        <!-- Provides the application the proper gutter -->

        <div class="top-page-header setting-top page-header-with-banner plan-top-header " :lang="locale">


          <v-tabs
            v-model="tabs"
            background-color="transparent"
            class="custom-tab "
          >
            <v-tab v-for="item in items" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
        </div>

        <v-container fluid class="v-main-content automation-top stepper-template">
          <!-- If using vue-router -->
          <template fluid>
            <v-card elevation="3" class="pa-4 pt-0 rounded-lg extra-padding">
              <!-- Tabs Open -->

              <v-tabs-items v-model="tabs">
                <v-tab-item>
                  <v-card flat>
                    <v-card-text class="pt-0">
                      <v-form v-model="valid" ref="userDetailSettingForm">
                        <!-- <v-row class="mt-0">
                          <v-col cols="12" sm="5">
                            <v-btn @click="goToCP" color="primary" depressed
                              >{{$t('changePass.chngPass')}}</v-btn
                            >
                          </v-col>
                        </v-row> -->
                        <div class="profile-col mt-0">
                          <!-- <v-divider></v-divider> -->
                             <v-row class="mt-0">
                            <v-col cols="12" sm="6">
                              <h3 class="heading--text mb-2">
                                {{ $t('settings.profileInfo') }}
                              </h3>
                            </v-col>
                          </v-row>
                          <!--  <v-row class="mt-0 mb-2">
                            <v-col cols="12" class="d-flex align-end">

                                  <croppa
                                   v-model="imgCropper"
                                      canvas-color="#e6e6e6"
                                          :width="180"
                                          :height="180"
                                          :placeholder="profilePic?'':' '"
                                          placeholder-color="#000"
                                          :placeholder-font-size="16"
                                          :show-remove-button="false"
                                          remove-button-color="#017982 "
                                          remove-button-size="18"
                                           :prevent-white-space="true"
                                           :disable-click-to-choose="true"
                                            @file-choose="handleProfilePicChoose"
                                          @image-remove="handleProfilePicRemove">
                                    <img v-if="profilePic" crossOrigin="anonymous" slot="placeholder" :src="imgKitUrl + '/' + profilePic">
                                      <img v-else src="../assets/images/default-image.png" crossOrigin="anonymous" slot="placeholder" >
                                  </croppa>

                                            <v-icon class="delete-pic" color="error" v-if="profilePic && !picSelected" @click="deleteProfilePicture()" >mdi-close</v-icon >

                                                <div class="profile-pic-edit d-flex align-center justify-center">
                                                    <v-icon large  v-if="!profilePic && !picSelected" @click="imgCropper.chooseFile()">mdi-plus</v-icon >

                                                     <v-icon class="pencil" v-if="picSelected" @click="imgCropper.remove()">mdi-close</v-icon >
                                                    <v-icon class="pencil"  v-if="profilePic && !picSelected" @click="imgCropper.chooseFile()">mdi-pencil</v-icon >
                                                 </div>


                                      <v-btn v-if="picSelected" @click="updateProfilePicture()" large class="ml-8" color="primary">
                                        Save
                                      </v-btn>

                            </v-col>

                          </v-row> -->

                          <v-row class="mt-2">
                            <v-col cols="12" sm="5">
                              <v-text-field
                                :label="$t('teamMgmt.first')"
                                placeholder="First Name"
                                v-model="updateDetails.FirstName"
                                required
                                outlined
                                dense
                                :rules="nameRules"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="5">
                              <v-text-field
                                :label="$t('teamMgmt.last')"
                                placeholder="Last Name"
                                v-model="updateDetails.LastName"
                                required
                                outlined
                                dense
                                :rules="nameRules"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row class="mt-0">
                            <!-- <v-col cols="12" sm="5">
                              <vue-tel-input v-model="updateDetails.Phone" v-bind="phoneProps"  @input="onInput"  :class="{ 'error-border': phoneNumberError }"></vue-tel-input>
                                  <div v-if="phoneNumberError" class="error--text text-caption ml-3 v-messages__message">{{ phoneNumberError }}</div>
                                <v-text-field
                                :label="$t('teamMgmt.phone')"
                                placeholder="Phone"
                                v-model="updateDetails.Phone"
                                required
                                outlined
                                dense
                                prepend-inner-icon="mdi-phone"
                                :rules="phoneRules"
                                v-mask="'###-###-####'"
                              ></v-text-field> 
                            </v-col> -->
                            <v-col cols="12" sm="5">
                              <v-text-field
                                :label="$t('teamMgmt.email')"
                                placeholder="Email"
                                v-model="email"
                                required
                                outlined
                                dense
                                disabled
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="5">
                              <v-text-field
                                :label="$t('teamMgmt.job')"
                                placeholder="Job Title"
                                v-model="updateDetails.JobTitle"
                                required
                                outlined
                                dense
                                :rules="jobRules"
                              ></v-text-field>
                            </v-col>

                          </v-row>
                          <!-- <v-row class="mt-0">
                              <v-col cols="12" sm="5">
                              <v-text-field
                                :label="$t('teamMgmt.company')"
                                placeholder="Company"
                                v-model="updateDetails.CompanyName"
                                required
                                outlined
                                dense
                                :rules="companyRules"
                                :disabled="isStaff"
                              ></v-text-field>
                            </v-col>>

                            <v-col cols="12" sm="5">
                              <v-text-field
                                :label="$t('teamMgmt.job')"
                                placeholder="Job Title"
                                v-model="updateDetails.JobTitle"
                                required
                                outlined
                                dense
                                :rules="jobRules"
                              ></v-text-field>
                            </v-col> 
                          </v-row> -->
                            <v-row class="mt-0">
                            <v-col cols="12" >
                              <v-btn
                                color="primary"
                                class="mr-3 w-xsm-100 mb-2"
                                depressed
                                :disabled="!valid"
                                @click="updateProfile()"
                                >{{ $t('settings.updateProf') }}</v-btn
                              >

                              <v-btn
                                @click="goToCP"
                                color="primary"
                                class="w-xsm-100 mb-2"
                                depressed
                                >{{ $t('changePass.chngPass') }}</v-btn
                              >
                            </v-col>
                          </v-row>

                        </div>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <!--payment-info-->
                <!-- <v-tab-item>
                  <v-card flat>
                    <v-card-title
                      class="px-0 pt-0 d-flex justify-space-between"
                    >
                      <h3 class="mt-0 heading--text mb-2">
                        {{ $t('settings.payMethod') }}
                      </h3>
                      <v-dialog v-model="add_card_dialog" max-width="600px">
                        <template v-slot:activator="{ on, attrs }">
                          <div class="my-2">
                            <v-btn
                              color="primary"
                              depressed
                              medium
                              rounded
                              v-bind="attrs"
                              v-on="on"
                              class="ml-4"
                              ><v-icon>mdi-plus</v-icon
                              >{{ $t('settings.addCard') }}</v-btn
                            >
                          </div>
                        </template>
                        <v-card class="pa-5">
                          <v-card-title
                            class="d-flex justify-space-between pa-0 mb-10"
                          >
                            <h4>{{ $t('settings.addCredit') }}</h4>
                            <v-icon @click="add_card_dialog = false"
                              >mdi-close</v-icon
                            >
                          </v-card-title>
                          <v-card-text class="pa-0">
                            <v-form
                              ref="add_card_form"
                              class="mb-5"
                              v-model="add_card_valid"
                              lazy-validation
                              v-on:submit.prevent
                            >
                              <v-row class="mt-0">
                                <v-col cols="12" sm="12" class="py-0">
                                  <label class="black--text mb-3 d-flex">{{
                                    $t('editCard.cardNumber')
                                  }}</label>
                                  <v-text-field
                                    :label="$t('editCard.cardNumber')"
                                    placeholder="Card Number"
                                    v-model="add_number"
                                    required
                                    outlined
                                    dense
                                    :rules="numberRules"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row class="mt-0">
                                <v-col cols="12" sm="6" class="py-0">
                                  <label class="black--text mb-3 d-flex">{{
                                    $t('editCard.expiryDate')
                                  }}</label>
                                  <v-text-field
                                    :label="$t('labels.mmYY')"
                                    placeholder="MM/YYYY"
                                    v-model="add_expiry"
                                    required
                                    outlined
                                    dense
                                    :rules="expiryRules"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="py-0">
                                  <label class="black--text mb-3 d-flex"
                                    >CVV</label
                                  >
                                  <v-text-field
                                    label="CVV"
                                    placeholder="CVV"
                                    v-model="add_cvv"
                                    required
                                    outlined
                                    dense
                                    :rules="expiryCVV"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-checkbox
                                v-model="add_primary"
                                :label="$t('settings.primaryPayment')"
                                color="primary"
                                hide-details
                                class="mt-1"
                              ></v-checkbox>
                              <v-row class="mt-5">
                                <v-col cols="12" sm="12" class="py-0">
                                  <v-btn
                                    color="primary"
                                    class="mr-3"
                                    :disabled="enableAdd || !add_card_valid"
                                    depressed
                                    @click="addCustCard"
                                    >{{ $t('settings.addCard') }}</v-btn
                                  >
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </v-card-title>
                    <v-card-text class="px-0">
                      <template>
                        <v-data-table
                          :headers="cardsHeaders"
                          :items="cardsItems"
                          :items-per-page="5"
                          :no-data-text="$t('kai.showDataText')"
                          class="elevation-0"
                        >
                          <template v-slot:item.plus="{ item }">
                            <label>{{ item.card.number }}</label>
                            <v-btn
                              v-if="item.card.primary"
                              color="primary"
                              elevation="0"
                              small
                              rounded
                              >Primary</v-btn
                            >
                          </template>
                          <template v-slot:item.actions="{ item }">
                            <v-icon
                              small
                              class="mr-2"
                              @click="deletecard(item)"
                            >
                              mdi-delete-outline
                            </v-icon>
                          </template>
                        </v-data-table>
                      </template>
                      <EditCard :editCardData="editCardData" />

                      <h4 class="mt-3 mb-3 heading--text" @click="creds">
                        {{ $t('settings.invoiceHistory') }}
                      </h4>
                      <template>
                        <v-data-table
                          :headers="invoiceHeaders"
                          :items="invoiceItems"
                          :items-per-page="5"
                          :no-data-text="$t('kai.showDataText')"
                          class="elevation-0"
                        >
                          <template v-slot:item.actions="{ item }">
                            <v-icon
                              small
                              class="mr-2"
                              @click="viewDLInvoice(item.invoice.id, 'inline')"
                            >
                              mdi-eye
                            </v-icon>
                            <v-icon
                              small
                              class="mr-2"
                              @click="
                                viewDLInvoice(item.invoice.id, 'attachment')
                              "
                            >
                              mdi-download
                            </v-icon>
                          </template>
                        </v-data-table>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-tab-item> -->

                <!--billing-info-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text class="pt-0">
                      <v-form v-model="valid1" ref="userDetailSettingForm">
                        <!-- <v-row class="mt-0">
                          <v-col cols="12" sm="5">
                            <v-btn @click="goToCP" color="primary" depressed
                              >{{$t('changePass.chngPass')}}</v-btn
                            >
                          </v-col>
                        </v-row> -->
                        <div class="profile-col mt-0">
                          <!-- <v-divider></v-divider> -->
                          <v-row class="mt-0">
                            <v-col cols="12" sm="6">
                              <h3 class="heading--text mb-2">
                                {{ this.$t('billingDetails.billInfo') }}
                              </h3>
                            </v-col>
                          </v-row>
                          <v-row class="mt-2">
                            <v-col cols="12" sm="5">
                              <v-text-field
                                v-model="billingAddress.company"
                                :label="$t('billingDetails.compName')"
                                :placeholder="$t('billingDetails.compName')"
                                outlined
                                dense
                                :rules="nameRules"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="5">
                              <v-text-field
                                v-model="billingAddress.line1"
                                :label="$t('billingDetails.addLine') + '1'"
                                :placeholder="$t('billingDetails.addLine') + '1'"
                                outlined
                                dense
                                :rules="generalVal"
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row class="mt-0">
                            <v-col cols="12" sm="5">
                              <v-text-field
                                v-model="billingAddress.line2"
                                :label="$t('billingDetails.addLine') + '2'"
                                :placeholder="$t('billingDetails.addLine') + '2'"
                                outlined
                                dense
                                :rules="generalVal"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="5">
                              <v-text-field
                                v-model="billingAddress.line3"
                                :label="$t('billingDetails.addLine') + '3'"
                                :placeholder="$t('billingDetails.addLine') + '3'"
                                outlined
                                dense
                                :rules="generalVal"
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row class="mt-0">
                            <v-col cols="12" sm="5">
                              <v-text-field
                                v-model="billingAddress.city"
                                :label="$t('billingDetails.city')"
                                :placeholder="$t('billingDetails.city')"
                                outlined
                                dense
                                :rules="generalVal"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="5">
                              <v-text-field
                                v-model="billingAddress.zip"
                                :label="$t('billingDetails.zip')"
                                :placeholder="$t('billingDetails.zip')"
                                outlined
                                dense
                                :rules="generalVal"
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row class="mt-0">
                            <v-col cols="12" sm="5">
                              <v-autocomplete
                              clearable
                              outlined
                              :placeholder="$t('createCampaign.countries')"
                              dense
                              chips
                              small-chips
                              deletable-chips
                              :label="$t('createCampaign.countries')"
                              v-model="billingAddress.country"
                              :items="countries"
                              item-text="Name"
                              item-value="Code"
                            >
                              <template v-slot:item="data">
                                <template>
                                    <country-flag
                                      :country="data.item.Code.toLowerCase()"
                                    />
                                  <v-list-item-content class="mt-2 mx-2">
                                    <v-list-item-title>{{
                                      data.item.Name
                                    }}</v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="5">
                              <v-text-field
                                v-model="billingAddress.vat_number"
                                :label="$t('billingDetails.vat')"
                                :placeholder="$t('billingDetails.vat')"
                                outlined
                                dense
                                :rules="generalVal"
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row class="mt-0">
                            <v-col cols="12" sm="5">
                              <v-btn
                                color="primary"
                                class="mr-3"
                                depressed
                                :disabled="!valid1"
                                @click="updateBillingAddr()"
                                >{{ this.$t('billingDetails.updateBillAdd') }}</v-btn
                              >
                            </v-col>
                          </v-row>
                        </div>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
              <!-- Tabs Open -->
            </v-card>
            <LoadingAlert
              :loading="loadingAlertFlag"
              :loadingText="loadingAlertText"
            />
            <InfoAlert
              :InfoAlert="infoAlertFlag"
              :InfoAlertTitle="infoAlertTitle"
              :InfoAlertMessage="infoAlertMessage"
              @emitInfoAlertOkClicked="clearInfoAlert"
            />
            <DecisionAlert
              :DecisionAlert="decisionAlertFlag"
              :DecisionAlertTitle="decisionAlertTitle"
              :DecisionAlertMessage="decisionAlertMessage"
              :DecisionAlertData="decisionAlertData"
              @emitDecisionAlertCancelClicked="clearDecisionModel"
              @emitDecisionAlertOkClicked="decisionOkAction($event)"
            />
          </template>
        </v-container>
      </div>
    </v-app>
  </div>
</template>
<style>
.vue-tel-input .vti__dropdown-list {
  height:150px ;
}
</style>
<script>
import Vue from 'vue';
import { LSS } from '@core/services';
import LoadingAlert from '../components/LoadingAlert';
import InfoAlert from '../components/InfoAlert';
import DecisionAlert from '../components/DecisionAlert';
import EditCard from '../components/chargebee/EditCard.vue';
import { decodeToken } from '@shared/utils/commom.util';
import {
  ALL_CARDS,
  ADD_CARD,
  DEL_CARD,
  ALL_TRANS,
  ALL_INVOICES,
  DL_INVOICE,
} from '../utils/apptweakqueries';
import { UPDATE_BILLING_ADDR, UPDATE_PROFILE, /* UPDATE_PROFILE_PICTURE */ } from 'src/utils/apollo-mutations';
import { API_ORY_URL } from '@config';
import VueMask from 'v-mask';
import { LoginService } from '@services/login.service';
import { HttpService } from '@core/services'
import { COUNTRIES } from 'src/utils/constants';
import { GET_BILLING_ADDR } from 'src/utils/apollo-queries';

Vue.use(VueMask);
export default Vue.extend({
  name: 'Settings',
  components: {
    LoadingAlert,
    InfoAlert,
    DecisionAlert,
    EditCard,
  },
  data() {
    return {
      phoneNumberError:"",
      syncStatus: false,
      locale: 'en',
      profilePic: '',
      picSelected: false,
      imgCropper: {},
      fsLoader: null,
      isStaff: true,
      credCount: 0,
      //cards
      add_number: '',
      editCardData: {
        hello: 'hello',
      },
      add_cvv: null,
      add_year: '',
      add_month: '',
      add_expiry: '',
      add_primary: false,
      cardlist: [],
      transList: [],
      add_card_dialog: false,
      add_card_valid: false,
      enableAdd: false,
      valid: false,
      valid1:false,
      memberValid: false,
      // numberRules: [
      //   (v) => !!v || 'Card Number is required',
      //   (v) =>
      //     (v && v.length == 16) ||
      //     'Card Number must be numerical and 16 digits long',
      //   (v) =>
      //     /[0-9]{16}/.test(v) ||
      //     'Card Number must be numerical 16 and digits long',
      // ],
      // expiryRules: [
      //   (v) => !!v || 'Expiry Month and Year is required',
      //   (v) =>
      //     (v && v.length == 7) || 'Expiry date must be MM/YYYY - ex. 07/2023',
      //   (v) => (v.substring(0,2) > 0 && v.substring(0,2) < 13) || 'Invalid expiry month',
      //   (v) =>
      //     /(0[1-9]|1[0-2])\/([0-9]{4})/.test(v) ||
      //     'Expiry date must be MM/YYYY - ex. 07/2023',
      //   (v) => (v.substring(3) > 2020 && v.substring(3) < 2099) || 'Invalid expiry year',
      // ],
      // expiryCVV: [
      //   (v) => !!v || 'Card CVV is required',
      //   (v) =>
      //     (v && v.length == 3) ||
      //     'Card CVV must be numerical and 3 digits long',
      //   (v) =>
      //     /[0-9]{3}/.test(v) || 'Card CVV must be numerical and 3 digits long',
      // ],
      // nameRules: [
      //   (v) => !!v || this.$t('createCampaignRules.fNamereq'),
      // ],
      // phoneRules: [
      //   (v) => !!v || this.$t('createCampaignRules.phoneReq'),
      // ],
      // companyRules: [
      //   (v) => !!v || this.$t('createCampaignRules.cmpniNameReq'),
      // ],
      // jobRules: [
      //   (v) => !!v || this.$t('createCampaignRules.jobTitleReq'),
      // ],
      // emailRules: [
      //   v => !!v || this.$t('createCampaignRules.emailReq'),
      //   v => /.+@.+/.test(v) || this.$t('createCampaignRules.emailValid'),
      // ],
      add_loading: false,
      addMsgModel: false,
      addMessage: '',
      addMsgTitle: '',

      loadingAlertFlag: false,
      loadingAlertText: 'Validating...',

      infoAlertFlag: false,
      infoAlertTitle: 'Info',
      infoAlertMessage: '',

      decisionAlertFlag: false,
      decisionAlertTitle: 'Decision',
      decisionAlertMessage: '',
      decisionAlertData: {},

      countries: COUNTRIES,

      billingAddress: {
        company: LSS.company,
        line1: '',
        line2: '',
        line3: '',
        city: '',
        zip: '',
        country: '',
        vat_number: ''
      },
      StoredBillingAddress:{},

      // invoiceHeaders: [
      //   {
      //     text: this.$t('settings.invoiceId'),
      //     align: 'start',
      //     sortable: false,
      //     value: 'invoice.id',
      //   },
      //   { text: this.$t('settings.dueDate'), value: 'invoice.due_date' },
      //   { text: this.$t('settings.paidAt'), value: 'invoice.paid_at' },
      //   { text: this.$t('settings.recure'), value: 'invoice.recurring' },
      //   { text: this.$t('settings.total'), value: 'invoice.total' },
      //   { text: this.$t('settings.status'), value: 'invoice.status' },
      //   { text: this.$t('settings.action'), value: 'actions' },
      // ],
      invoiceItems: [],

      // transactionHeaders: [
      //   {
      //     text: this.$t('settings.invoiceId'),
      //     align: 'start',
      //     sortable: false,
      //     value: 'invoice.id',
      //   },
      //   { text: this.$t('settings.dueDate'), value: 'invoice.due_date' },
      //   { text: this.$t('settings.paidAt'), value: 'invoice.paid_at' },
      //   { text: this.$t('settings.recure'), value: 'invoice.recurring' },
      //   { text: this.$t('settings.total'), value: 'invoice.total' },
      //   { text: this.$t('settings.status'), value: 'invoice.status' },
      //   { text: this.$t('settings.action'), value: 'actions' },
      // ],
      transactionItems: [],

      // cardsHeaders: [
      //   {
      //     text: this.$t('settings.cardId'),
      //     align: 'start',
      //     sortable: false,
      //     value: 'card.id',
      //   },
      //   { text: this.$t('settings.type'), value: 'card.type' },
      //   // { text: 'Number', value: 'card.number' },
      //   { text: this.$t('settings.number'), value: 'plus' },
      //   { text: this.$t('settings.expiry'), value: 'card.expiry' },
      //   { text: this.$t('settings.action'), value: 'actions' },
      // ],
      cardsItems: [
        //TODO
        // {
        //   card: {
        //     id: 1,
        //     type: 'Visa',
        //     number: '9876543210123456',
        //     expiry: '12/2023',
        //     primary: true
        //   },
        // },
        // {
        //   card: {
        //     id: 2,
        //     type: 'Master',
        //     number: '1234567890123456',
        //     expiry: '09/2025',
        //     primary: false
        //   },
        // },
      ],

      email: '',
      password: '',
      checkbox: false,
      inviteDialog: false,
      select: '',
      emailForInvite: '',

      tabs: null,
      text: 'text',

      updateDetails: {
        FirstName: '',
        LastName: '',
        Phone: '',
        CompanyName: '',
        JobTitle: '',
      },
      addMemberDialog: false,

      memberDetails: {
        FirstName: '',
        LastName: '',
        Phone: '',
        Email: '',
        CompanyName: '',
        JobTitle: '',
      },

      carddetails: [],

      transactionhistory: [],

      isCheckboxTrue: false,

      onClickShow: false,

      userDetails: {},

      phone: {
        number: "",
        valid: false,
        country: undefined
      },

      phoneProps:{
         autoFormat:true,
         mode:'international',
         preferredCountries:[
           'US'
         ],
         validCharactersOnly:true
         /* dropdownOptions:{
           showSearchBox:true,
           showFlags:true,
           showDialCodeInList:true,
           tabindex:0,
           width:'300px'
         } */
       }
    };
  },

  computed: {
    items: {
      get() {
        const items = [this.$t('settings.accountSetting')];

        if (decodeToken(LSS.token)) {
          if (decodeToken(LSS.token).Role == 'ADMIN') {
            items.push(this.$t('billingDetails.billInfo'));
          }
        }

        return items;
      },
    },

    invoiceHeaders: {
      get() {
        return [
          {
            text: this.$t('settings.invoiceId'),
            align: 'start',
            sortable: false,
            value: 'invoice.id',
          },
          { text: this.$t('settings.dueDate'), value: 'invoice.due_date' },
          { text: this.$t('settings.paidAt'), value: 'invoice.paid_at' },
          { text: this.$t('settings.recure'), value: 'invoice.recurring' },
          { text: this.$t('settings.total'), value: 'invoice.total' },
          { text: this.$t('settings.status'), value: 'invoice.status' },
          { text: this.$t('settings.action'), value: 'actions' },
        ];
      },
    },

    transactionHeaders: {
      get() {
        return [
          {
            text: this.$t('settings.invoiceId'),
            align: 'start',
            sortable: false,
            value: 'invoice.id',
          },
          { text: this.$t('settings.dueDate'), value: 'invoice.due_date' },
          { text: this.$t('settings.paidAt'), value: 'invoice.paid_at' },
          { text: this.$t('settings.recure'), value: 'invoice.recurring' },
          { text: this.$t('settings.total'), value: 'invoice.total' },
          { text: this.$t('settings.status'), value: 'invoice.status' },
          { text: this.$t('settings.action'), value: 'actions' },
        ];
      },
    },

    cardsHeaders: {
      get() {
        return [
          {
            text: this.$t('settings.cardId'),
            align: 'start',
            sortable: false,
            value: 'payment_source.card.masked_number',
          },
          {
            text: this.$t('settings.type'),
            value: 'payment_source.card.brand',
          },
          {
            text: this.$t('settings.expiryMonth'),
            value: 'payment_source.card.expiry_month',
          },
          {
            text: this.$t('settings.expiry'),
            value: 'payment_source.card.expiry_year',
          },
          { text: this.$t('settings.action'), value: 'actions' },
        ];
      },
    },

    nameRules: {
      get() {
        return [
          (v) => !!v || this.$t('createCampaignRules.fNamereq'),
          v => (v && v.length <= 50) || this.$t('createCampaignRules.textMax'),
          v => (v && v.length >= 2) || this.$t('createCampaignRules.textMin'),
          v=> (!/[<>;\\[\]{}&%]/g.test(v)) || this.$t('createCampaignRules.specialCharNotAllowed'),
          v=> (!/\s\s/.test(v)) ||  this.$t('createCampaignRules.onlySinglespaceAllowed'),
        ];
      },
    },

    generalVal: {
      get() {
        return [
          v => ( v.length <= 50) || this.$t('createCampaignRules.textMax'),
        ];
      },
    },

    phoneRules: {
      get() {
        return [(v) => !!v || this.$t('createCampaignRules.phoneReq')];
      },
    },

    companyRules: {
      get() {
        return [(v) => !!v || this.$t('createCampaignRules.cmpniNameReq')];
      },
    },

    jobRules: {
      get() {
        return [
          (v) => !!v || this.$t('createCampaignRules.jobTitleReq'),
           v => (v && v.length <= 50) || this.$t('createCampaignRules.textMax'),
          v => (v && v.length >= 2) || this.$t('createCampaignRules.textMin'),
          v=> (!/[<>;\\[\]{}&%]/g.test(v)) || this.$t('createCampaignRules.specialCharNotAllowed'),
          v=>  (!/\s\s/.test(v)) ||  this.$t('createCampaignRules.onlySinglespaceAllowed'),
          ];
      },
    },

    emailRules: {
      get() {
        return [
          (v) => !!v || this.$t('createCampaignRules.emailReq'),
          (v) => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || this.$t('createCampaignRules.emailValid'),
        ];
      },
    },
    numberRules: {
      get() {
        return [
          (v) => !!v || this.$t('createCampaignRules.cardNoReq'),
          (v) =>
            (v && v.length == 16) ||
            this.$t('createCampaignRules.cardNo16Digit'),
          (v) =>
            /[0-9]{16}/.test(v) || this.$t('createCampaignRules.cardNo16Digit'),
        ];
      },
    },

    expiryRules: {
      get() {
        return [
          (v) => !!v || this.$t('createCampaignRules.expiryMonthReq'),
          (v) =>
            (v && v.length == 7) || this.$t('createCampaignRules.expiryMMYY'),
          (v) =>
            (v.substring(0, 2) > 0 && v.substring(0, 2) < 13) ||
            this.$t('createCampaignRules.invalidExpiry'),
          (v) =>
            /(0[1-9]|1[0-2])\/([0-9]{4})/.test(v) ||
            this.$t('createCampaignRules.expiryMMYY'),
          (v) =>
            (v.substring(3) > 2020 && v.substring(3) < 2099) ||
            this.$t('createCampaignRules.invalidMonth'),
        ];
      },
    },

    expiryCVV: {
      get() {
        return [
          (v) => !!v || this.$t('createCampaignRules.cvvReq'),
          (v) =>
            (v && v.length == 3) || this.$t('createCampaignRules.cvvNum3Digit'),
          (v) =>
            /[0-9]{3}/.test(v) || this.$t('createCampaignRules.cvvNum3Digit'),
        ];
      },
    },
  },

  methods: {
    /* deleteProfilePicture(){
      this.fsLoader = this.$loading.show();
      this.$apollo
        .mutate({
          mutation: UPDATE_PROFILE_PICTURE,
          variables: {
            req: { data: '' }
          },
        })
        .then((res) => {
          this.fsLoader.hide();

          if(res.data.updateProfilePicture.successMessage) {
            this.$notify.success("Profile picture removed successfully");
            // this.imgCropper.remove()
            return this.getUserDetails(decodeToken(LSS.token).UserId).then(() => this.imgCropper.remove());;
          }

          this.$notify.error("Unable to remove profile picture!");
        })
        .catch((error) => {
          this.fsLoader.hide();
          console.log(error);
        });

    },

    async updateProfilePicture() {
      this.fsLoader = this.$loading.show();
      const url = this.imgCropper.generateDataUrl();
      const auth = await LoginService.getImgKitAuth();
      const formdata = new FormData();
      formdata.append('file', url);
      formdata.append('publicKey', IMGKIT_PUB);
      formdata.append('signature', auth.signature);
      formdata.append('expire', auth.expire);
      formdata.append('token', auth.token);
      formdata.append('fileName', Date.now() + '.jpg');

      const res = await HttpService.post('https://upload.imagekit.io/api/v1/files/upload', formdata);

      this.$apollo
        .mutate({
          mutation: UPDATE_PROFILE_PICTURE,
          variables: {
            req: { data: res }
          },
        })
        .then((res) => {
          this.fsLoader.hide();

          if(res.data.updateProfilePicture.successMessage) {
            this.$notify.success(res.data.updateProfilePicture.successMessage);
            return this.getUserDetails(decodeToken(LSS.token).UserId).then(() => this.imgCropper.remove());
          }

          this.$notify.error("Unable to update profile picture!");
        })
        .catch((error) => {
          this.fsLoader.hide();
          console.log(error);
        });
    },
    handleProfilePicChoose() {
      this.picSelected = true;
    },
    handleProfilePicRemove() {
      this.picSelected = false;
    }, */
 onInput: function(number, phoneObject) {
 const cleanedNumber = number.replace(/[^0-9+]/g, '');
  const plusCount = (number.match(/\+/g) || []).length;
  if (plusCount <= 1 && /^[0-9+() -]+$/.test(cleanedNumber)) {
    this.phone.number = phoneObject.formatted;
    this.phone.valid = phoneObject.valid;
    this.updateDetails.phone = phoneObject.formatted;
    this.phoneNumberError = ""; // Clear the error message
  } else {
    if(cleanedNumber!=''){
     this.phone.valid = false;
    this.phoneNumberError = this.$t('createCampaignRules.invalidPhoneFormat');
    return false;
    }

  }

},
    creds() {
      this.credCount++;
      if (this.credCount == 20) {
        console.log('jay.kanakala@gmail.com');
        this.credCount = 0;
      }
    },
    addCustCard() {
      this.$refs.add_card_form.validate();
      if (!this.add_number || !this.add_expiry || !this.add_cvv) {
        //console.log('empty');
        return;
      }
      if (!this.add_card_valid) {
        return;
      }
      // const newcard = {
      //   card: {
      //     id: 3,
      //     type: 'Master',
      //     number: this.add_number,
      //     expiry: this.add_expiry,
      //     primary: this.add_primary,
      //   },
      // };
      this.loadingAlertFlag = true;
      this.loadingAlertText = 'Validating...';
      let splitExpiry = this.add_expiry.split('/');
      this.$apollo
        .query({
          query: ADD_CARD,
          variables: {
            custId: decodeToken(LSS.token).TeamID,
            number: this.add_number,
            cvv: parseInt(this.add_cvv),
            year: parseInt(splitExpiry[1]),
            month: parseInt(splitExpiry[0]),
            primary: this.add_primary,
          },
        })
        .then((res) => {
          //console.log(res.data);
          if (res.data.addCard.code == 3) {
            this.loadingAlertFlag = false;
            this.add_card_dialog = false;
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'Error!!!';
            this.infoAlertMessage =
              'User does not exist in subscription list...';
            return;
          } else if (res.data.addCard.code == 4) {
            this.loadingAlertFlag = false;
            this.add_card_dialog = false;
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'Error!!!';
            this.infoAlertMessage = 'Invalid Card Number';
            return;
          } else if (res.data.addCard.code == 5) {
            this.loadingAlertFlag = false;
            this.add_card_dialog = false;
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'Error!!!';
            this.infoAlertMessage = 'Authorization failed';
            return;
          } else if (res.data.addCard.code == 9) {
            this.loadingAlertFlag = false;
            this.add_card_dialog = false;
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'Error!!!';
            this.infoAlertMessage = 'Something went wrong...';
            return;
          } else if (res.data.addCard.code == 0) {
            this.loadingAlertFlag = false;
            this.add_card_dialog = false;
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'Success!!!';
            this.infoAlertMessage = 'Card Added successfully';
            //this.cardsItems.push(newcard);
            this.add_number = '';
            this.add_expiry = '';
            this.add_cvv = '';
            this.getCards();
          } else {
            this.loadingAlertFlag = false;
            this.add_card_dialog = false;
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'Error!!!';
            this.infoAlertMessage = 'Something went wrong...';
            return;
          }
        })
        .catch((error) => {
          //console.log(error);
          this.loadingAlertFlag = false;
          this.add_card_dialog = false;
          this.infoAlertFlag = true;
          this.infoAlertTitle = 'Error!!!';
          this.infoAlertMessage = 'Something went wrong...';
        });
    },
    clearInfoAlert() {
      //console.log('clearInfoAlert');
      this.infoAlertFlag = false;
    },
    deleteCustCard() {
      //console.log('deleteCustCard');
    },

    getBillingAddr() {
      this.$apollo.query({
        query: GET_BILLING_ADDR
      }).then(res => {
        if(res.data.getCBBillingAddr) this.billingAddress = res.data.getCBBillingAddr;
          this.StoredBillingAddress=JSON.stringify(this.billingAddress);
      }).catch(err => err)

    },

  updateBillingAddr() {
      this.fsLoader = this.$loading.show();
      const previousBillingAddressString =this.StoredBillingAddress;
      this.$apollo
        .mutate({
          mutation: UPDATE_BILLING_ADDR,
          variables: {
            inputs: this.billingAddress
          },
        })
        .then((res) => {
          this.fsLoader.hide();
          if(res.data.updateBillingAddr.successMessage) {
             const updatedBillingAddressString = JSON.stringify(this.billingAddress);
             if (previousBillingAddressString !== updatedBillingAddressString) {
              this.getBillingAddr();
              this.getUserDetails(decodeToken(LSS.token).UserId);
              this.infoAlertFlag = true;
              this.infoAlertTitle = this.$t('settings.successTitle');
              this.infoAlertMessage = this.$t('settings.successMsg');
            } else {
              this.infoAlertFlag = true;
              this.infoAlertTitle = this.$t('settings.NoSuccessTitle');
              this.infoAlertMessage = this.$t('settings.NoSuccessMsg');
            }
          }else{
          this.$notify.error(res.data.updateBillingAddr?.content?.errorMessage);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCards() {
      //console.log('getCards');
      this.$apollo
        .query({
          query: ALL_CARDS,
          variables: {
            custId: decodeToken(LSS.token).TeamID,
            limit: 3,
            type: 'card',
          },
        })
        .then((res) => {
          //console.log(res.data);
          if (!res.data.getAllCards) {
            return;
          }
          this.cardsItems = res.data.getAllCards.list;
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    getTrans() {
      //console.log('getTrans');
      this.$apollo
        .query({
          query: ALL_TRANS,
          variables: {
            custId: decodeToken(LSS.token).TeamID,
            transId: 'all',
            limit: 3,
            offset: '["1600622718000","266000001127"]',
          },
        })
        .then((res) => {
          //console.log(res.data);
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    getInvoices() {
      //console.log('getInvoices');
      const custId = decodeToken(LSS.token).TeamID;
      this.$apollo
        .query({
          query: ALL_INVOICES,
          variables: {
            custId: custId,
            limit: 10,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.invoiceItems = res.data.getAllInvoices.list
            ? res.data.getAllInvoices.list
            : [];
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    goToCP() {
      window.location.href = API_ORY_URL + 'self-service/settings/browser';
    },
    viewDLInvoice(id, type) {
      //console.log(id);
      this.loadingAlertFlag = true;
      this.loadingAlertText = 'Preparing...';
      //console.log('downloadInvoice');
      this.$apollo
        .query({
          query: DL_INVOICE,
          variables: {
            invoice_id: id,
            disposition_type: type,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.loadingAlertFlag = false;
          const url = res.data.dlInvoice.download
            ? res.data.dlInvoice.download.download_url
            : '';
          window.open(url);
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    updateProfile() {
      //console.log('updateProfile');
      this.loadingAlertFlag = true;
      this.loadingAlertText = 'Updating...';
      const updateProfileData = {
        FirstName: this.updateDetails.FirstName,
        LastName: this.updateDetails.LastName,
        Phone: this.updateDetails.Phone,
        CompanyName: this.updateDetails.CompanyName,
        JobTitle: this.updateDetails.JobTitle,
        Role: decodeToken(LSS.token).Role,
      };

      this.$apollo
        .mutate({
          mutation: UPDATE_PROFILE,
          variables: {
            securityPrincipleID: decodeToken(LSS.token).UserId,
            updateProfileReq: updateProfileData,
          },
        })
        .then((res) => {
          this.loadingAlertFlag = false;
          if (res.data.updateProfile.code == 0) {
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'Success!!!';
            this.infoAlertMessage = 'User Details Updated successfully';
          } else {
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'No Changes Detected!!!';
            this.infoAlertMessage = 'Please make the changes before clicking the Update button';
          }
          if (res.data.updateProfile) {
            //console.log(res.data.updateProfile)
            const userDetails = {
              CompanyName: res.data.updateProfile.content.CompanyName,
              FirstName: res.data.updateProfile.content.FirstName,
              JobTitle: res.data.updateProfile.content.JobTitle,
              LastName: res.data.updateProfile.content.LastName,
              Phone: res.data.updateProfile.content.Phone,
            };
            this.updateData(userDetails);
            this.$root.$emit('userDetailsUpdated', userDetails);
            /* if(userDetails?.picture?.filePath) {
              this.profilePic = userDetails?.picture?.filePath;
            } */
            this.getUserDetails(decodeToken(LSS.token).UserId);
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    async getUserDetails(UserID) {
    
      const token = await LoginService.getUserDetails({
        UserID: UserID,
        SessId: null,
      });
      const user = decodeToken(token);
      //console.log(user);
      LSS.token = token;
      this.$root.$emit('userNameFetched', user);
      return user;
    },
    editcard(data) {
      //console.log(data);
      const card = {
        id: data.card.id,
        expiry: data.card.expiry,
        number: data.card.number,
        cvv: data.card.type,
        now: Date.now(),
      };
      this.editCardData = card;
    },
    deletecard(data) {
      this.decisionAlertFlag = true;
      this.decisionAlertTitle = 'Warning!!!';
      this.decisionAlertMessage = 'Are you sure ?';
      this.decisionAlertData = {
        id: data.payment_source.id,
        from: 'deleteCard',
      };
    },
    clearDecisionModel() {
      this.decisionAlertFlag = false;
    },
    decisionOkAction(event) {
      //console.log(event);
      this.decisionAlertFlag = false;
      this.loadingAlertFlag = true;
      this.loadingAlertText = 'Deleting...';
      const id = event.id;
      const idString = id.toString();
      this.$apollo
        .query({
          query: DEL_CARD,
          variables: {
            cardId: idString,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.loadingAlertFlag = false;
          this.infoAlertFlag = true;
          this.infoAlertTitle = 'Success!!!';
          this.infoAlertMessage = 'Card Deleted successfully';
          //console.log(this.cardsItems);
          this.cardsItems = this.cardsItems.filter(
            (obj) => obj.payment_source.id !== id
          );
          //console.log(this.cardsItems);
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    updateData(data) {
      this.updateDetails = data;
      this.email = decodeToken(LSS.token).Email;
    },
  },
  mounted() {
    this.syncStatus = LSS.activeSync
    Vue.prototype.$sckt.on("syncprogress:event", () => {
      this.syncStatus = LSS.activeSync
    });
    this.locale = localStorage.getItem('locale') || 'en'
    this.getCards();
    this.getTrans();
    // this.getInvoices();
    this.getBillingAddr()
    this.add_card_valid = false;
    this.enableAdd = false;
    this.isStaff = decodeToken(LSS.token).Role == 'STAFF' ? true : false;
    this.updateData(decodeToken(LSS.token));

    /* const user = decodeToken(LSS.token);
    if(user?.picture?.filePath) {
        this.profilePic = user?.picture?.filePath;
    } */

    this.$root.$on('locale-changed', (locale) => {
      this.locale = locale.localeCode
      //this.$refs.formStep1.resetValidation()
      this.$refs.userDetailSettingForm.validate();
      this.$refs.add_card_form.validate();
    });

    this.$root.$on('userNameFetched', (userDetails) => {
      /* if(userDetails?.picture?.filePath) {
        this.profilePic = userDetails?.picture?.filePath;
      }
      else{
        this.profilePic = '';
      } */
      //console.log(userDetails)
      this.isStaff = decodeToken(LSS.token).Role == 'STAFF' ? true : false;
      this.updateData(decodeToken(LSS.token));
    });
  },
});
</script>
<style scoped>
.error-border{
 border:2px solid #b51c15;
}
</style>
